<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $tc("company") }}</span>
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <form class="mt-5">
          <b-checkbox v-model="consent">
            {{ $t("consent") }}<br />
            <small>
              {{ $t("see") }}
              <a href="https://reduct.net/en/cloud/#consent" target="_blank">
                reduct.net/en/cloud/#consent
              </a>
            </small>
          </b-checkbox>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Company",
  data() {
    return {
      loading: true,
      company: null,
      consent: false,
    };
  },
  computed: {
    ...mapState({
      entity: (state) => state.auth.entity,
    }),
  },
  watch: {
    consent(value) {
      // Prevent submit when initial value is set
      if (this.loading) {
        return;
      }
      this.updateCompanyConsent({
        id: this.company.id,
        payload: { consent: value },
      }).then(() => {
        this.$buefy.toast.open({
          message: this.$t("company_updated"),
          type: "is-primary",
        });
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getCompany", "updateCompanyConsent"]),
    load() {
      this.getCompany(this.entity.id).then((company) => {
        this.company = company;
        this.consent = this.company.supportAndAnalysisConsent;
        this.$nextTick().then(() => {
          this.loading = false;
        });
      });
    },
  },
};
</script>
